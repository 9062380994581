import React from "react"
import Img from "gatsby-image"

import "./services-image.css"


const getIndex = (size) => (
  Math.floor(Math.random()*size)
)

const getUniqueImage = (images) => {
  const unique = []
  while (unique.length < 4) {
    let uniqueIndex = getIndex(images.length)
    if (!unique.includes(uniqueIndex)) {
      unique.push(uniqueIndex)
    }
  }
  return unique
}

const ServicesImages = (props) => {
  const {images} = props.props
  const index = getUniqueImage(images)

  return (
    <div className="flex justify-center flex-col items-center md:justify-center md:flex-row pt-4 pb-12">
      <Img fixed={images[index[0]].fixed} key={index[0]} className="lg:mr-5 service-image" />
      <Img fixed={images[index[1]].fixed} key={index[1]} className="lg:mr-5 service-image" />
      <Img fixed={images[index[2]].fixed} key={index[2]} className="lg:mr-5 service-image" />
      <Img fixed={images[index[3]].fixed} key={index[3]} className="lg:mr-5 service-image" />
    </div>
  )
}

export default ServicesImages