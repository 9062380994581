import React, { useState } from "react"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ServicesImages from "../components/servicesImages"

const AboutPage = (props) => {
  const { nodes } = props.data.services
  const [show, imageSlug] = useState('kitchens')

  return (
    <Layout>
      <SEO title="About us" />
      <section className="mx-0 md:mx-12 container">
        <h1 className="relative orange-underline text-center">About us</h1>
        <p className="mt-5">Fast, efficient, and honest, L&L Building and Maintenance has become a reputable and
        well-known service provider. Our team is up for every job, managing projects with the
        skill and experience our clients have come to expect. We want our customers to be
        satisfied with our work, which is why we provide open communication channels
      throughout the duration of each project.</p>
      </section>

      <section className="container mx-12 pl-12">
        <object width="100%" height="650">
          <param name="houzz movie" value="https://www.houzz.co.uk/embed/highlightVideo/a110aeaf0ed9297d" />
          <embed className="h-48 md:h-60 lg:h-96" src="https://www.houzz.co.uk/embed/highlightVideo/a110aeaf0ed9297d" width="70%" />
        </object>
      </section>

      <section className="bg-grayLight">
        <h1 className="relative orange-underline text-center pt-12">Services</h1>
        <div className="flex justify-center flex-col md:flex-row">
          <div className="flex flex-col w-full md:w-3/12 mt-5 mr-12">
            <p className="pt-5 text-center font-medium text-lg">Interiors</p>
            <ul className="ml-2 list-none flex flex-wrap justify-center">
              <li>
                <button
                  className="transition shadow rounded hover:bg-orange hover:text-white bg-white border border-orange p-1 text-xs mr-1"
                  aria-label="show pictures of kitchens"
                  onClick={() => imageSlug('kitchens')}
                >
                  Kitchens
              </button>
              </li>
              <li>
                <button
                  className="transition shadow rounded hover:bg-orange hover:text-white bg-white border border-orange p-1 text-xs mr-1"
                  aria-label="show pictures of bathrooms"
                  onClick={() => imageSlug('bathrooms')}
                >
                  Bathrooms
              </button>
              </li>
              <li>
                <button
                  className="transition shadow rounded hover:bg-orange hover:text-white bg-white border border-orange p-1 text-xs mr-1"
                  aria-label="show pictures of rooms"
                  onClick={() => imageSlug('rooms')}
                >
                  Rooms
              </button>
              </li>
              <li>
                <button
                  className="transition shadow rounded hover:bg-orange hover:text-white bg-white border border-orange p-1 text-xs mr-1"
                  aria-label="show pictures of hallways"
                  onClick={() => imageSlug('halls')}
                >
                  Halls/Stairs
              </button>
              </li>
            </ul>
          </div>

          <div className="flex flex-col w-full md:w-3/12 mt-5 mr-12">
            <p className="pt-5 text-center font-medium text-lg">Exteriors</p>
            <ul className="ml-0 list-none flex flex-wrap justify-center">
              <li>
                <button
                  className="transition shadow rounded hover:bg-orange hover:text-white bg-white border border-orange p-1 text-xs mr-1"
                  aria-label="show pictures of extensions"
                  onClick={() => imageSlug('extensions')}
                >
                  Extensions
            </button>
              </li>
              <li>
                <button
                  className="transition shadow rounded hover:bg-orange hover:text-white bg-white border border-orange p-1 text-xs mr-1"
                  aria-label="show pictures of loft conversions"
                  onClick={() => imageSlug('loft-conversions')}
                >
                  Loft conversions
            </button>
              </li>
            </ul>
          </div>

          <div className="flex flex-col w-full md:w-3/12 mt-5 mr-12">
            <p className="pt-5 text-center font-medium text-lg">Other Services</p>
            <ul className="ml-0 list-none flex flex-wrap justify-center text-xs">
              <li>
                <button
                  className="transition shadow rounded hover:bg-orange hover:text-white bg-white border border-orange p-1 text-xs mr-1"
                  aria-label="show pictures of kitchens"
                  onClick={() => imageSlug('gardens')}
                >
                  Gardens/Pations
            </button>
              </li>
              <li>
                <button
                  className="transition shadow rounded hover:bg-orange hover:text-white bg-white border border-orange p-1 text-xs mr-1"
                  aria-label="show pictures of kitchens"
                  onClick={() => imageSlug('doors-windows')}
                >
                  Doors/Windows
            </button>
              </li>
              <li>
                <button
                  className="transition shadow rounded hover:bg-orange hover:text-white bg-white border border-orange p-1 text-xs mr-1"
                  aria-label="show pictures of kitchens"
                  onClick={() => imageSlug('other-services')}
                >
                  Others
            </button>
              </li>
            </ul>
          </div>

        </div>

        {nodes.map(node => {
          if (node.slug === show) {
            return (
              <ServicesImages props={node} key={node.slug} />
            )
          }
          return null
        })}
      </section>

      <section className="container">
        <p className="py-24 md:py-40 text-2xl font-light">You can count on us to be professional, timely, efficient and make sure you are satisfied every step of the way. Hire us for any service and learn how we cater to  your needs, ensuring the results you need and deserve.</p>
      </section>

      <section className="bg-grayDarkest">
        <div className="container">
          <div className="flex md:flex-row flex-col-reverse items-center lg:mx-24 pt-16">
            <Img fixed={props.data.IMG2286.nodes[0].fixed} className="flex-shrink-0" />
            <p className="mt-8 ml-6 text-white">Since its founding, L&L Building and Maintenance has been one of the most trusted names in the industry and is committed to get the job done with the experience and skills necessary to tackle just about every type of job that comes our way.</p>
          </div>
          <div className="lg:mx-24 pb-12 flex flex-col md:flex-row items-center">
            <Img fixed={props.data.toilet.nodes[0].fixed} className="flex-shrink-0" />
            <Img fixed={props.data.kitchen.nodes[0].fixed} className="flex-shrink-0" />
            <Img fixed={props.data.windows.nodes[0].fixed} className="flex-shrink-0" />
            <Img fixed={props.data.IMG2283.nodes[0].fixed} className="flex-shrink-0" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage


export const query = graphql`
query {
  IMG2286: allContentfulAsset(filter: {file: {fileName: {eq: "IMG_2286.jpeg"}}}) {
    nodes {
      fixed(height: 200, width: 200) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
  }
  toilet: allContentfulAsset(filter: {file: {fileName: {eq: "Maidstone - Toilet.jpg"}}}) {
    nodes {
      fixed(height: 200, width: 200) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
  }
  kitchen: allContentfulAsset(filter: {file: {fileName: {eq: "Maidstone - Kitchen.jpg"}}}) {
    nodes {
      fixed(height: 200, width: 200) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
  }
  windows: allContentfulAsset(filter: {file: {fileName: {eq: "Clapham - windows.jpeg"}}}) {
    nodes {
      fixed(height: 200, width: 200) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
  }
  IMG2283: allContentfulAsset(filter: {file: {fileName: {eq: "IMG_2283.jpeg"}}}) {
    nodes {
      fixed(height: 200, width: 200) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
  }
  services: allContentfulPortfolioWork(filter: {categories: {eq: "services"}}) {
    nodes {
      title
      slug
      images {
        fixed(width: 250, height: 250) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
  }
}

`